/* TechStackIcons.css */
.tech-stack-icons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.tech-stack-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tech-icon {
  max-width: 100px; /* Set a maximum width */
  max-height: 100px; /* Set a maximum height */
  width: auto;
  height: auto;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s, transform 0.5s;
  margin: 10px 0;
}

.tech-icon.revealed {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 768px) {
  .tech-icon {
    max-width: 60px; /* Reduce max width on smaller screens */
    max-height: 60px; /* Reduce max height on smaller screens */
  }
}

@media (max-width: 480px) {
  .tech-icon {
    max-width: 40px; /* Further reduce max width on very small screens */
    max-height: 40px; /* Further reduce max height on very small screens */
  }
}
