.project-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-image: url('../animations/project-background.jpg'); /* Update this path to your background image */
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Ensures the background image stays fixed during scrolling */
  width: 100%;
  height: 100vh; /* Adjust the height as needed */
  padding: 20px;
}

.project-display {
  width: 80%;
  height: 60vh; /* Adjusted to give more vertical space */
  margin-bottom: 20px;
  border: 1px solid #ccc;
  overflow: hidden;
  perspective: 1000px; /* Add perspective for 3D effect */
  position: relative;
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

.project-display .content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: transform 0.6s ease-in-out;
  display: flex; /* Use Flexbox to center items */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

.project-display .content.flip {
  transform: rotateY(180deg);
}

.project-display iframe {
  width: 100%;
  height: 100%;
}

.project-display .lottie-animation {
  width: 30%; /* Adjust size to be smaller */
  height: auto;
  display: flex; /* Use Flexbox to center items */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

.project-display .visualization {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-description {
  margin-bottom: 20px;
}

.navigation {
  display: flex;
  align-items: center;
}

button {
  padding: 10px 20px;
  margin: 0 20px;
  background-color: #1e90ff;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #104e8b;
}

.progress-bar {
  display: flex;
  align-items: center;
}

.progress-dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #ccc;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.progress-dot.active {
  background-color: #1e90ff;
}
