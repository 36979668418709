/* NavBar.css */
.navbar {
  position: fixed; /* Fix the navbar at the top */
  top: 0;
  width: 100%;
  height: 60px;

  color: var(--navbar-text-color);
  display: flex;
  justify-content: space-between; /* Space between brand and links */
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 10; /* Ensure navbar is above other elements */
  font-family: 'Roboto', sans-serif; /* Modern font */
}

.navbar-brand {
  font-size: 1.5em;
  font-weight: bold;
 
  background-size: cover; /* Ensure the background image covers the entire brand */
  background-position: center; /* Center the background image */
  padding: 10px; /* Add padding to make space for the background image */
  border-radius: 5px; /* Optional: Add border radius to make it look better */
}

.navbar-links {
  list-style: none;
  display: flex; /* Display items in a row */
  margin: 0;
  padding: 0;
}

.navbar-links li {
  margin: 0 10px; /* Reduce the spacing between items */
}

.navbar a {
  color: var(--navbar-link-color); /* Use CSS variables for dynamic theming */
  text-decoration: none;
  font-size: 1em;
  padding: 10px 20px;
  transition: color 0.3s, background-color 0.3s;
}

.navbar a:hover {
  color: var(--navbar-link-hover-color);
  background-color: var(--navbar-link-hover-bg);
}

/* Mobile Navigation */
.mobile-navbar {

  background-size: cover; /* Ensure the background image covers the entire brand */
  background-position: center; /* Center the background image */
  position: fixed;
  opacity: 0.8;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  background-color: var(--navbar-background);
  padding: 10px 0;
  box-shadow: 0 2px 5px rgba(85, 81, 81, 0.2);
}

.mobile-navbar-button {
 
  /*background-image: url('../animations/menu.jpg'); */ /* Add background image for button */
  background-size: cover; /* Ensure the background image covers the entire button */
  background-position: center; /* Center the background image */
  color: black;
  background-color: var(--navbar-background);
  border: none;
  padding: 10px 20px;
  font-size: 1.2em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.mobile-navbar-button:hover {
  background-color: var(--navbar-button-hover-background);
  color: var(--navbar-button-hover-color);
}

.mobile-navbar-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--navbar-background);
  width: 100%;
  padding: 10px 0;
  margin-top: 10px;
  list-style: none;
}

.mobile-navbar-links li {
  margin: 10px 0;
}

.mobile-navbar-links a {
  color: var(--navbar-link-color);
  text-decoration: none;
  font-size: 1.2em;
  padding: 10px 20px;
  transition: color 0.3s, background-color 0.3s;
}

.mobile-navbar-links a:hover {
  color: var(--navbar-link-hover-color);
  background-color: var(--navbar-link-hover-bg);
}
