/* Home.css */
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #1e90ff, #87ceeb);
  color: white;
  text-align: center;
  font-family: 'Arial', sans-serif;
  position: relative; /* Make home relative to position animation */
  padding: 20px;
  box-sizing: border-box;
}

.header-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  opacity: 0.1; /* Make video less visible */

}

.header-video.hidden {
  display: none;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 0; /* Place overlay above video but below content */
}

.home-content {
  max-width: 1200px;
  width: 100%;
  z-index: 1; /* Ensure content is above video and overlay */
  padding: 20px; /* Add padding to ensure content doesn't touch edges */
  box-sizing: border-box;
}

.home-main {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the animation */
  position: relative;
}


.go-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  cursor: pointer;
  z-index: 9; /* Ensure go animation is on top initially */
}

@media (max-width: 768px) {
  .home-main {
    flex-direction: column;
  }

  .tech-stack-icons {
    margin-bottom: 20px;
  }

  .home-content {
    padding: 10px; /* Reduce padding on smaller screens */
  }

  button {
    width: 100%; /* Make button full-width on smaller screens */
    margin-top: 20px; /* Add margin-top for spacing */
  }
}
