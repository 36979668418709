/* AnimationContainer.css */
.animation-container {
  width: 300px;
  height: 300px;
  margin: 1px auto; /* Center the animation and add margin */
  z-index: 0; /* Ensure animation is behind content */
  pointer-events: none; /* Make sure animation does not block interactions */
  transition: opacity 1s ease, transform 1s ease; /* Add transition for appearance */
  opacity: 0; /* Start hidden */
  transform: translateY(20px); /* Start off-screen */
}

