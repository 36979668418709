/* TechStackIcons.css */
.tech-stack-icons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.tech-stack-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tech-icon {
  max-width: 100px; /* Set a maximum width */
  max-height: 100px; /* Set a maximum height */
  width: auto;
  height: auto;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s, transform 0.5s, box-shadow 0.3s ease, transform 0.3s ease;
  margin: 10px 0;
}

.tech-icon.revealed {
  opacity: 1;
  transform: translateY(0);
}

.tech-icon:hover {
  animation: pulse 1.5s infinite;
  box-shadow: 0 0 15px rgba(30, 144, 255, 0.6); /* Glow effect */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .tech-icon {
    max-width: 60px; /* Reduce max width on smaller screens */
    max-height: 60px; /* Reduce max height on smaller screens */
  }
}

@media (max-width: 480px) {
  .tech-icon {
    max-width: 40px; /* Further reduce max width on very small screens */
    max-height: 40px; /* Further reduce max height on very small screens */
  }
}

@media (max-width: 768px) {
  button {
    width: 80%; /* Make button full-width on smaller screens */
    margin-top: 20px; /* Add margin-top for spacing */
    padding: 0.5em 2em;
    background-color: transparent;
    color: white;
    border: 2px solid white;
    border-radius: 30px; /* Make the buttons rounded */
    cursor: pointer;
    margin: 2em;
    font-size: 1em;
    text-transform: uppercase; /* Make the text uppercase */
    transition: background-color 0.3s, color 0.3s;
  }
}
