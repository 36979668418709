.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  background: linear-gradient(135deg, #1e90ff, #87ceeb);
  height: 100vh;
  color: white;
  font-family: 'Arial', sans-serif;
  position: relative;
}

.right-animation {
  width: 150px;
  height: 150px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.main-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 70%; /* Increased width */
  max-width: 1200px; /* Maximum width */
  background-color: rgba(255, 255, 255, 0.8);
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: black;
  position: relative;
}

.contact-info {
  width: 45%;
}

.contact-info h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.contact-info p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.social-icons {
  display: flex;
  align-items: center;
  gap: 20px;
}

.left-animation {
  width: 50%; /* Adjust width as needed */
  height: 50%; /* Adjust height as needed */
  margin-left: 30%; /* Space between the animation and the GitHub icon */
}

.social-icons a {
  color: #1e90ff;
  font-size: 2em;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #104e8b;
}

.contact-form {
  width: 45%;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.form-group {
  position: relative;
  margin-bottom: 20px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group label {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 0.9em;
  color: #888;
  transition: 0.3s;
}

.form-group input:focus + label,
.form-group input:valid + label,
.form-group textarea:focus + label,
.form-group textarea:valid + label {
  top: -20px;
  left: 10px;
  font-size: 0.8em;
  color: #1e90ff;
}

button {
  padding: 10px 20px;
  font-size: 1em;
  color: white;
  background-color: #1e90ff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #104e8b;
}
