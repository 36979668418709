@import "~motion-ui/dist/motion-ui.min.css";
@import url('https://fonts.googleapis.com/css2?family=Hacked&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.about::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.about {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Include the Roboto font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  margin: 0;
  padding-top: 60px; /* Ensure content starts below the navbar */
  font-family: 'Roboto', sans-serif; /* Apply the modern font globally */
}

.light-mode {
  background-color: #f0f0f0;
  color: #333;
}

.dark-mode {
  background-color: #121212;
  color: #e0e0e0;
}
